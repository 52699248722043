import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/plugins/i18n";
import { authGuard } from "@tt/vue-auth0";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "loading",
    component: () => import("@/views/LoadingView.vue"),
    meta: {
      active: true
    }
  },
  {
    path: '/default',
    name: 'default',
    redirect: "/sku"
  },
  {
    path: "/sku",
    name: "sku",
    component: () => import("@/views/SkusView"),
    meta: {
      label: i18n.t("skus.title"),
      active: true
    }
  },
  {
    path: "/sku/:sku",
    name: "sku/detail",
    component: () => import("@/views/SkusView"),
    meta: {
      active: true,
      render: false
    }
  },
  {
    path: "/expected-stock",
    name: "expected-stock",
    component: () => import("@/views/ExpectedStockView"),
    meta: {
      label: i18n.t("expected-stock.title"),
      active: true
    }
  },
  {
    path: "/locations",
    name: "locations",
    component: () => import("@/views/LocationsView"),
    meta: {
      label: i18n.t("locations.title"),
      active: true
    }
  },
  {
    path: "/virtual-stocks",
    name: "virtualStockOverview",
    component: () => import("@/views/VirtualsView"),
    meta: {
      label: i18n.t("virtuals.title"),
      active: true
    }
  },
  {
    path: "/adapters",
    name: "adapters",
    component: () => import("@/views/AdaptersView"),
    meta: {
      label: i18n.t("adapters.title"),
      active: true
    }
  },
  {
    path: "/reservations",
    name: "reservations",
    component: () => import("@/views/ReservationsOverview.vue"),
    meta: {
      label: i18n.t("reservations.title"),
      active: true,
      render: false,
      badge: {
        content: () => store.state.storeReservationItems.statistics.total.toString() ?? "0",
        color: 'primary',
      }
    },
    children: [
      {
        path: "/reservations/order",
        name: "order-reservations",
        component: () => import("@/components/onlineReservations/OnlineReservationsOverview.vue"),
        meta: {
          label: i18n.t("online-reservations.title"),
          active: true,

        }
      },
      {
        path: "/reservations/store",
        name: "store-reservations",
        component: () => import("@/components/storeReservations/StoreReservationsOverview.vue"),
        meta: {
          label: i18n.t("store-reservations.title"),
          active: true,
          badge: {
            content: () => store.state.storeReservationItems.statistics.total.toString() ?? "0",
            color: 'primary',
          },
        }
      },
    ]
  },
  {
    path: "/synchronisation",
    name: "synchronisation",
    component: () => import("@/views/SynchronisationLogView"),
    meta: {
      label: i18n.t("synchronisation.title"),
      active: true
    }
  },
  {
    path: "/stock-mutations",
    name: "mutations",
    component: () => import("@/views/StockMutationsView"),
    meta: {
      label: i18n.t("mutations.title"),
      active: true
    }
  },
];

// create router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// auth check
router.beforeResolve(authGuard);
router.beforeEach((to, from, next) => {
  if (to.path !== '/') {
    if (store.getters["settings/settings"] === null) {
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router;

